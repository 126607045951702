


















































import Vue from 'vue';
import Component from 'vue-class-component';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import SygniLoader from '@/components/layout/SygniLoader.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import EditorComponent from '@/modules/genprox/components/wysiwyg/EditorComponent.vue';
import { Template, TemplateAttachment, TemplateStatuses } from '../store/types';
import { required } from 'vuelidate/lib/validators';
import { unescape } from 'lodash';
import SygniRoundedButton from '@/components/buttons/SygniRoundedButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import { BTable } from 'bootstrap-vue';
import { NavigationGuardNext, Route } from 'vue-router';
import Attachments from '@/modules/genprox/modules/fund/modules/capital-rise/modules/templates/components/Attachments.vue';
import { Prop } from 'vue-property-decorator/lib/decorators/Prop';
import { UploadProgress } from '@/modules/shared/utils/utils';
import FileUploader from '@/components/FileUploader.vue';
import AttachmentModal from '../components/AttachmentModal.vue';

Component.registerHooks(['validations', 'beforeRouteLeave']);
@Component({
  components: { BTable, SygniLoader, SygniModal, SygniRectButton, SygniContainerTitle, SygniInput, SygniCheckbox, SygniRoundedButton, SygniFileBox, EditorComponent, Attachments, FileUploader, AttachmentModal },
})
export default class Templates extends Vue {
  @Prop({default: {progress: -1}}) uploadProgress!: UploadProgress;
  readonly supportedFileFormats: Array<string> = ['jpg', 'jpeg', 'png'];
  nextRoute: NavigationGuardNext<Vue> = null;
  showLeavingModal: boolean = false;
  isLeavingModalLoading: boolean = false;
  showAttachmentPreviewModal: boolean = false;
  selectedAttachment: any = null;

  tagSearch: string = '';
  title: string = '';
  description: string = '';
  editable: boolean = true;
  simple: boolean = false;
  showEditor: boolean = false;
  isLoading: boolean = false;
  hasChanges: boolean = false;

  attachmentsToUpload: TemplateAttachment[] = [];

  template: Template = {
    title: '',
    description: '',
    content: '',
    isAttachment: false,
    status: TemplateStatuses.DRAFT,
    attachments: [],
  }

  get routeId(): string | null {
    return this.$route?.params?.id ? this.$route.params.id : null;
  }

  get filteredTags() {
    return this.tags?.filter((tag: string) => tag?.toLowerCase()?.includes(this.tagSearch?.toLowerCase()))
  }
  
  get tags() {
    return this.$store.getters['genprox/documentDetails'];
  }

  get readOnlyMode() {
    return this.$route.name === 'template-preview' ? true : false;
  }

  get attachmentsEditMode() {
    return this.$route.name === 'template-preview' ? 'preview' : 'edit';
  }

  get hasTemplateLogo() {
    const div = document.createElement('div');
    div.innerHTML = (this.$refs.editorEl as EditorComponent)?.getEditorBody();

    return Array.from(div.querySelectorAll('img')).length;
  }

  closeAttachmentPreviewModal() {
    this.showAttachmentPreviewModal = false;
  }

  openAttachmentPreview(attachment: any) {
    this.selectedAttachment = attachment;
    this.showAttachmentPreviewModal = true;
  }

  async addFile(files: File[]) {
    const blob = await fetch(URL.createObjectURL(files[0])).then(res => res.blob());
    
    const result = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

    const div = document.createElement('div');
    div.innerHTML = (this.$refs.editorEl as EditorComponent)?.getEditorBody();

    const images = Array.from(div.querySelectorAll('img'));

    if(images.length) {
      for (let i = 0; i < images.length; i++) {
        images[i].parentNode.removeChild(images[i]); 
      }
    }

    (this.$refs.editorEl as EditorComponent).setEditorContent(div.innerHTML);

    const logo = `<img class="logo" width="120" src="${result}" alt="Logo" />`;

    (this.$refs.editorEl as EditorComponent).insertLogo(logo);
  }

  changeRoute() {
    if (this.nextRoute) this.nextRoute();
  }

  async confirmSavingTemplate() {
    this.saveTemplate(this.nextRoute);
  }

  handleContentUpdate() {
    this.hasChanges = true;
  }

  handleInput(validation: any) {
    validation?.$touch();
    this.hasChanges = true;
  }

  handleAttachmentsUpdate() {
    this.hasChanges = true;
  }

  insertTag(tag: string): void {
    (this.$refs.editorEl as EditorComponent).insertTag(tag);
    this.hasChanges = true;
  }

  async saveAttachments(id: string) {
    const attachmentsToPost: TemplateAttachment[] = (this.$refs.attachments as Attachments).attachmentsToPost;
    const attachmentsToDelete: string[] = (this.$refs.attachments as Attachments).attachmentsToDelete;
    const attachmentsToEdit: TemplateAttachment[] = (this.$refs.attachments as Attachments).attachmentsToEdit;

    if(attachmentsToPost.length) {
      await this.$store.dispatch('templates/postAttachments', {
        attachmentId: id,
        attachments: attachmentsToPost,
      });
    }

    if(attachmentsToDelete.length) {
      await this.$store.dispatch('templates/deleteAttachments', {
        attachmentId: id,
        attachmentIds: attachmentsToDelete,
      })
    }

    if(attachmentsToEdit.length) {
      await this.$store.dispatch('templates/editAttachments', {
        attachmentId: id,
        attachments: attachmentsToEdit,
      })
    }
  }

  async saveTemplate(nextRoute?: NavigationGuardNext<Vue>) {
    this.$v.$touch();

    if(!this.$v.$error) {
      this.isLoading = true;
      const action = this.routeId ? 'editTemplate' : 'postTemplate';

      const payload: Template = {
        id: this.routeId ? this.routeId : '',
        title: this.template.title,
        description: this.template.description,
        content: unescape((this.$refs.editorEl as EditorComponent).getEditorBody()),
        status: this.template.status,
        isAttachment: this.template.isAttachment,
        attachments: this.template.attachments,
      }
      
      try {
        const response = await this.$store.dispatch(`templates/${action}`, payload);

        if(!this.template.isAttachment) {
          if(this.routeId) {
            await this.saveAttachments(this.routeId);
          } else {
            await this.saveAttachments(response);
          }
        }

        this.$notify({
          duration: 3000,
          type: 'success',
          title: 'Success',
          text: this.routeId ? `${!this.template.isAttachment ? 'Template' : 'Attachment'} saved.` : `${!this.template.isAttachment ? 'Template' : 'Attachment'} created.`
        });

        if(!this.routeId) {
          if(this.$route.path.includes('company')) {
            this.$router.push({ path: '/company/capital-rise', query: { page: 'templates' } });
          } else {
            this.$router.push({ path: '/fund/capital-rise', query: { page: 'templates' } });
          }
        } else {
          await this.loadTemplate();
          this.hasChanges = false;
        }

        if (nextRoute) nextRoute();

      } catch (e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
      }

      this.isLoading = false;
    } else {
      this.scrollContentToTop();
    }
  }

  scrollContentToTop(): void {
    const appContentDiv: HTMLDivElement = this.$root.$el.querySelector('.app-content') as HTMLDivElement | undefined;
    appContentDiv?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  async loadTemplate() {
    const template = await this.$store.dispatch('templates/getTemplate', this.routeId);
    
    this.template.title = template.title;
    this.template.description = template.description;
    this.template.content = unescape(template.content);
    this.template.isAttachment = template.isAttachment;
    this.template.status = template.status;
    this.template.attachments = template.attachments;
    
    // do not copy template.attachments
    this.attachmentsToUpload = JSON.parse(JSON.stringify(template.attachments));
    
    (this.$refs.editorEl as EditorComponent).setEditorInitialContent(this.template.content);
    (this.$refs.editorEl as EditorComponent).setEditorContent(this.template.content);
  }

  async mounted() {
    if(this.routeId) {
      this.isLoading = true;
      try {
        await this.loadTemplate();
      } catch(e) {
        const errorMessage = this.$options.filters.errorHandler(e);
        this.$notify({
          duration: 2500,
          type: 'error',
          title: 'Error',
          text: this.$t(errorMessage).toString()
        })
        this.$router.push({ name: 'investors', query: { page: 'templates' } });
      }
      this.isLoading = false;
    } else {
      this.template.isAttachment = this.$route?.query?.type === 'attachment' ? true : false;
    }
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    if (this.hasChanges && this.routeId) {
      this.showLeavingModal = true;
      this.nextRoute = next;
    } else {
      next();
    }
  }

  validations() {
    return {
      template: {
        title: { required },
        description: { required },
      }
    }
  }
}
